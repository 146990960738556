import { Container, Image } from "@mantine/core";
import logo from "../imgs/logo.png";
import useFetch from '../hooks/useFetch';

import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { ActionIcon } from '@mantine/core';
import { IconAdjustments, IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandWhatsapp, IconLocation,
    IconMail,
IconMapPin, 
IconPhoneCall} from '@tabler/icons-react';
import { Link } from "react-router-dom";
const Footer = ({links}) => {
const { data: info } = useFetch("info");
// console.log(info)
const { t, i18n } = useTranslation();



return (

<>
    <div className="bg-blue-1">
        <Container size={'lg'} className="flex flex-col lg:flex-row justify-between">
            <div className="w-1/3">
                <Image src={logo} width={200} className="footer-logo py-8" />
                <div className="text-white-1 flex gap-2">
                  
                 
                    <IconMapPin />
                    {info&&
                    <Link target="_blank" className="text-white-0 no-underline	" to={info.location}>{info.address} </Link>

                    }

                </div>
                <br/>
                
                <div className="text-white-1 flex gap-2">
                    <IconPhoneCall />
                    {info&&
                    <Link className="text-white-0 no-underline	" to={"tel:"+info.phone}>{" "}{info.phone}</Link>
                    }
                </div>
                <br/>
                
                <div className="text-white-1 flex gap-2">
                    <IconMail />
                    {info&&
                   <Link className="text-white-0 no-underline	" to={"mailto:"+info.email}>{" "}{info.email}</Link>
                    }
                </div>
            </div>

            <div className=" py-8">
                <h3 className="text-white-0 font-normal">Explore</h3>
                {links.map((link,index) => (

                <div key={index}>
                    <HashLink  id={index} smooth to={link.link} className={` my-1 block underline-offset-8
                        text-white-0 no-underline`}>
                        {t('general.'+link.label)}
                    </HashLink>
                    <br />
                </div>
                ))}


            </div>
            <div className=" py-8">
                <h3 className="text-white-0 font-normal">Social Media</h3>

                {info&&
                <>
                    <Link to={info.facebook_link}>

                    <ActionIcon color="white" className="text-white-0" variant="transparent" size="xl">
                        <IconBrandFacebook size="2.125rem" />
                    </ActionIcon>
                    </Link>
                    <Link to={info.insta_link}>

                    <ActionIcon color="white" className="text-white-0" variant="transparent" size="xl">
                        <IconBrandInstagram size="2.125rem" />
                    </ActionIcon>
                    </Link>
                    <Link to={`https://wa.me/+2${info.whatsapp}`}>

                    <ActionIcon color="white" className="text-white-0" variant="transparent" size="xl">
                        <IconBrandWhatsapp size="2.125rem" />
                    </ActionIcon>
                    </Link>

                    <Link to={info.linkedin_link}>

                    <ActionIcon color="white" className="text-white-0 font-thin" variant="transparent" size="xl">
                        <IconBrandLinkedin size="2.125rem" />
                    </ActionIcon>
                    </Link>

                </>
                }
            </div>

        </Container>
        <Container size={'lg'} className="py-4">

        <Link className="text-white-0" to={'/our-policies'}>{t('general.policy')}</Link>

        </Container>


    </div>
</>

);
}

export default Footer;
