import { Badge, Table } from "@mantine/core";
import { IconShare3 } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ServiceTable = ({service}) => {
    const { t, i18n } = useTranslation();

    return ( 
        <Table className=' mx-auto ar-dir' highlightOnHover>
                       
        <tbody>
{service.category &&
            <tr>
                <td >{t('general.category')}</td>
                <td className='font-medium text-right ar-text-left'>{service.category['title_'+i18n.language]}</td>
            </tr>
            
           }
           
           
          
            <tr>
            <td >{t('general.area')}</td>
            <td className='font-medium text-right ordinal ar-text-left'>{service.area}<span className='ordinal'>m2</span></td>
            </tr>
            <tr>
            <td >{t('general.depth')}</td>
            <td className='font-medium text-right ar-text-left'>{service.depth}m</td>
            </tr>
            <tr>
            <td >{t('general.address')}</td>
            <td className='font-medium text-right ar-text-left'>{service.address}</td>
            </tr>
            <tr>
            <td >{t('general.location')}</td>
            <td className='font-medium text-right ar-text-left'><Link to={service.location} target="_blank"><IconShare3 color="#23C2E4" /></Link></td>
            </tr>
            <tr>
            <td >{t('general.status')}</td>
            <td className='font-medium text-right ar-text-left'>
              <Badge color={service==='reviewing' ? 'cyan':(service==='approved'?'green':(service==='rejected'?'red':'teal'))} >
              {t('general.'+service.status)}</Badge>
                </td>
            </tr>
           
       
        </tbody>
    </Table>


     );
}
 
export default ServiceTable;