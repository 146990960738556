import { ActionIcon, Affix, Dialog, Flex, Text, rem } from "@mantine/core";
import { IconBrandWhatsapp, IconMessage } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Contact = () => {
    const { data: info } = useFetch("info");
    const [opened, { toggle, close }] = useDisclosure(false);
    const { data: chat , isPending } = useFetch("chat");
    const { t, i18n } = useTranslation();
    const [answer,setAnswer]=useState('');
    const [showAnswer,setShowAnswer]=useState(false)
    return ( 
        <>
        <Dialog className="max-h-[50vh] min-h-[20vh] overflow-auto overflow-x-hidden	"  opened={opened} withCloseButton onClose={close} size="lg" radius="md">
       
       
       {chat ?
                   Object.values(chat).map((msg, index) => (
                    <div key={index}>
                    <Text onClick={(e)=>{setAnswer(msg['answer_'+i18n.language]); setShowAnswer(true)}} c="white"  className="cursor-pointer rounded inline px-2 mt-3" bg={'myblue.3'} >{msg['question_'+i18n.language]}</Text>

                    <br/>
                    </div>
                    ))       
       :<></>}

       {showAnswer&&
              <div className="float-right inline cursor-pointer rounded  px-2 mt-3 rounded bg-white-1">
                    <Text className="" >{answer}</Text>
                    </div>
                    
                    }


      </Dialog>
        <Affix position={{ bottom: rem(23), right: rem(23) }}>
        <Flex 
        className='rounded'
        bg={"myblue.3"}
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap" >
        <ActionIcon size="xl" color="myblue.3" variant="filled" onClick={toggle}>
              <IconMessage />
            </ActionIcon>
{info &&  
            <Link to={`https://wa.me/+2${info.whatsapp}`}>
            <ActionIcon size="xl" color="myblue.3" variant="filled">
              <IconBrandWhatsapp />
            </ActionIcon>
        </Link>
}
        </Flex>
        </Affix>
        </>
     );
}
 
export default Contact;
