import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Center, Container, Loader, Skeleton } from "@mantine/core";
import { useParams } from "react-router-dom";
import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import parse from "html-react-parser";
import { IconCalendarWeek } from "@tabler/icons-react";

const Type = () => {


    const { id  } = useParams();
    const { t, i18n } = useTranslation();
  
    const { data: type ,isPending} = useFetch("type/"+id);

    return (  
    
    <div className="pb-[80px] ar-dir" >
   
   
   <Skeleton visible={isPending} height={'60vh'}>


    {type && 
      <div className="w-full  h-[60vh] overflow-hidden text-white-0 bg-cover bg-fixed bg-center" style={{backgroundImage:`url(${process.env.REACT_APP_BACKEND_URL+'/storage/'+type.img})`}}>

                   

         <div className='h-full  w-full type-overlay-div flex justify-start items-center' >
            <Container size={'lg'}>
            <h1 className='font-normal  mb-0  	'>{type['title_'+i18n.language]}</h1>
            </Container>
                </div> 
                </div>

              }
        </Skeleton>


                <Container size={'lg'}>
                  <h3 className="mt-12 font-normal">{t('general.projects')}</h3>
  {isPending ? 
    
    ( <Center>
     <Loader/>
     </Center>)
     :
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">

                { type.projects && type.projects.length > 0 ?(
Object.values(type.projects).map((project, index) => (
    <Card key={index} shadow="sm" padding="lg" radius="md" withBorder>
    <Card.Section>
      <Image
      className=""
        src={process.env.REACT_APP_BACKEND_URL+'/storage/'+project.cover_img}
        height={160}
        alt={project['title_'+i18n.language]}
      />
    </Card.Section>
    <Text fz="xs" className="py-2 flex text-dark-1 text-right" ><IconCalendarWeek size="1rem" /> {project.date}</Text> 

      <Text weight={500}>{project['title_'+i18n.language]}</Text>
       

    <Text size="sm" color="dimmed">
    {parse(project['desc_'+i18n.language])}
    </Text>

<Link className="no-underline" to={'/project/'+project.id+'/'+project.title_en}>

    <Button variant="light" color="blue" fullWidth mt="md" radius="md">
      {t('general.explore')}
    </Button>
    
</Link>
  </Card>

  

                
                        
            ))):
<p>{t('general.noProjects')}</p>
  }
                </div>
}

                
                </Container>
                
                
            
                
                
                
                
                
                </div>

    );
}
 
export default Type;