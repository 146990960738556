import { Accordion, Center, Container, Loader, Tabs } from "@mantine/core";
import useFetch from "../hooks/useFetch";
import OffersTab from "../components/OffersTab";
import { useTranslation } from "react-i18next";

const CompletedIffers = () => {
    const { data: offers , isPending:isPendingOffers} = useFetch("get-completed-offers");
    const { t, i18n } = useTranslation();

    return ( 
        <Container size={"lg"} className=" my-8 min-h-[60vh]">
        <Accordion variant="contained" >
            {isPendingOffers ? 
            (
            <Center className='py-12'>
            <Loader/>   
            </Center>)
           
         
           :
           (offers && offers.length >0 ?
           <OffersTab offers={offers}/>
           :
           <>{t('general.noOffers')}</>
           
           )

        }

     
            
          
            </Accordion>
            </Container>

     );
}
 
export default CompletedIffers;