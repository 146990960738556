import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container, Image, Skeleton } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { IconMapPin, IconMapPin2 } from "@tabler/icons-react";
import parse from "html-react-parser";

const Project = () => {

    
    const { id  } = useParams();
    const { t, i18n } = useTranslation();
    const isVideo = (media) => {
        // Assuming that videos have file extensions like .mp4, .webm, etc.
        const videoExtensions = ['.mp4', '.webm', '.ogg'];
        const extension = media.substring(media.lastIndexOf('.')).toLowerCase();
        return videoExtensions.includes(extension);
      };
    const { data: project , isPending} = useFetch("project/"+id);
    return ( 
    
    <>
     <Skeleton visible={isPending}>
     <div className="h-[80vh] flex">
  <Carousel height="100%" sx={{ flex: 1 }} withControls={true} slideSize="100%"  withIndicators>
  {project &&
  Object.values(project.media).map((media, index) => (
    <Carousel.Slide key={index} className='h-full'>
      {isVideo(media) ? (
        <video controls width="100%" height="100%">
          <source src={process.env.REACT_APP_BACKEND_URL + '/storage/' + media} type="video/mp4" />
        </video>
      ) : (
        <img 
          style={{
            objectFit: 'cover',
            objectPosition: 'center center',
            width: '100%',
            height: '100%',
          }}
          src={process.env.REACT_APP_BACKEND_URL + '/storage/' + media}
          alt={project['title_' + i18n.language]}
        />
      )}
    </Carousel.Slide>
  ))
}

  </Carousel>
</div>

</Skeleton>
<Container size={'lg'} className="ar-dir">
    { project && 
<>
    <p><IconMapPin /> {project['location_'+ i18n.language]}</p>
    <hr/>
    <h3>{project['title_' + i18n.language]}</h3>
    <p>{parse(project['desc_' + i18n.language])}</p>
    <h4>{t('general.designer')}</h4>
    <p>{parse(project['designer_' + i18n.language])}</p>

</>
    }
    </Container>
    </> 
    
    
    
    
    );
}
 
export default Project;