// firebase.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD31Lh-qE28TC-3QRPIH5TYyflxJqIh3jY",
    authDomain: "horizon-6c380.firebaseapp.com",
    projectId: "horizon-6c380",
    storageBucket: "horizon-6c380.appspot.com",
    messagingSenderId: "352505316584",
    appId: "1:352505316584:web:450a28ece1cfb77739f55d",
    measurementId: "G-ZPBF6RH2C0"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const dtoken=getToken(messaging, {vapidKey: "BCDE1uMnp1QpIOLDZRsvfA3dp3MnjabWAMngWApTpwNcVMdSXSlE-AqKdMBV8U01XZ2ydKbA02nRAtoIZYQJClE"});

export {messaging,dtoken};


