import useFetch from '../hooks/useFetch';
import { Container } from "@mantine/core";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Policies = () => {

    const { data: info } = useFetch("info");
    const { t, i18n } = useTranslation();

    return ( 
<>
{info &&
<Container size={'lg'} className="">

{parse(info['privacy_'+i18n.language])}
{parse(info['terms_'+i18n.language])}
{parse(info['refund_'+i18n.language])}

</Container>
}

</>


     );
}
 
export default Policies;