import { useDisclosure } from '@mantine/hooks';
import { Stepper,Modal, Button, Group, Input, Container, Select, NumberInput, Table } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from 'react';
import { Chip } from '@mantine/core';
import { IconBuildingSkyscraper, IconUser } from '@tabler/icons-react';
import useFetch from '../hooks/useFetch';
import axios from "axios";
import { AuthContext } from '../hooks/AuthContext';
import { notifications } from '@mantine/notifications';
import { Navigate, useNavigate } from 'react-router-dom';

const GetOffer = () => {
const { data: categories } = useFetch("categories");

const [opened, { open, close }] = useDisclosure(false);
const { t, i18n } = useTranslation();
const [active, setActive] = useState(0);
const [customerType,setCustomerType]=useState('personal');
const [corporateName,setCorporateName]=useState('');
const [corporateEmail,setCorporateEmail]=useState('');
const [categoryId,setCategoryId]=useState(null);
const [typeId,setTypeId]=useState(null);
const [equipmentId,setEquipmentId]=useState(null);
const [finishId,setFinishId]=useState(null);
const [selectedCategory,setSelectedCategory]=useState(null);
const [area,setArea]=useState('');
const [depth,setDepth]=useState('');
const [selectedType,setSelectedType]=useState(null);
const [selectedEquip,setSelectedEquip]=useState(null);
const [selectedFinish,setSelectedFinish]=useState(null);
const [categoryErr,setCategoryErr]=useState(false);
const [typeErr,setTypeErr]=useState(false);
const [equipErr,setEquipErr]=useState(false);
const [finishErr,setFinishErr]=useState(false);
const [price, setPrice] = useState(0);
const [ready,setReady]=useState(false);
const [loader, setLoader] = useState(false);
const { user } = useContext(AuthContext);
const navigate=useNavigate();

const openGetOffer=()=>{
    if (!user){
        navigate('/login')
    }
    else {
        if(user.phone==null){
            navigate('/phone');

        }
        else {
            open()

        }
    }
}
const changeCategory=(catId)=>{
setCategoryId(catId);
setSelectedCategory(categories.find(category => category.id === catId))
setCategoryErr(false)

}
const changeType=(typeId)=>{
setTypeId(typeId);
setSelectedType(selectedCategory.types.find(type => type.id === typeId))
setTypeErr(false)

}
const changeEquip=(equipId)=>{
setEquipmentId(equipId);
setSelectedEquip(selectedType.equipment.find(equip => equip.id === equipId))
setEquipErr(false)

}
const changeFinish=(finishId)=>{
setFinishId(finishId);
setSelectedFinish(selectedCategory.finishes.find(finish => finish.id === finishId))
setFinishErr(false)

}

const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current)); const prevStep=()=>
    setActive((current) => (current > 0 ? current - 1 : current));
    const handleSubmitFirstStep=(e)=>{
    e.preventDefault();
    nextStep()
    }
    const handleSubmitSecondStep=(e)=>{
    e.preventDefault();
    if(categoryId===null) setCategoryErr(true)
    else if (typeId===null) setTypeErr(true)
    else if (equipmentId===null) setEquipErr(true)
    else if (finishId===null) setFinishErr(true)
    else {
    const designPrice=selectedCategory.designprices.find(obj => area >= obj.from_area && area <= obj.to_area)
        setPrice((selectedType.civil_price+selectedEquip.price+selectedFinish.price+designPrice.price)*area) 
        nextStep()
        setReady(true)
        } } ;
        const sendOffer=()=>{
          setLoader(true)
          axios.post(process.env.REACT_APP_BACKEND_URL+"/api/add-offer",{
            customer_type: customerType,
            corporate_name: corporateName,
            corporate_email:corporateEmail,
            type_id: typeId,
            finish_id: finishId,
            price: price,
            area: area,
            depth: depth,
            equipment_id: equipmentId

              },{
              headers: {
              'Content-Type': "application/json",
              Authorization: `Bearer ${user['auth_token']}`
              },
              
              }) 
              .then(function (response){
                  setLoader(false)
                  notifications.show({
                    title: t('general.offerAdded'),
                    message: t('general.waitUpdates'),
                    color: 'teal',
            
                  })
                  setActive(0)
                  close();
              })
        }
        return ( <>
        <Modal size={'xl'} opened={opened} onClose={close} title={t('general.getOffer')} centered>
            <Stepper allowNextStepsSelect={false} className='' size="xs" active={active} onStepClick={setActive}
                breakpoint="sm">
                <Stepper.Step label={t('general.chooseOption')} description={t('general.chooseDesc')}>

                    <form onSubmit={handleSubmitFirstStep}>
                        <Chip.Group className="pt-20">
                            <div className='w-full lg:w-[60%] my-8 mx-auto flex justify-between align-center'>
                                <Chip value="personal" checked={customerType==='personal'? true:""} radius="sm"
                                    onClick={(e)=>setCustomerType(e.target.value)} size="xl" >
                                    <IconUser />
                                    <span>{t('general.personal')}</span>
                                </Chip>
                                <Chip value="corporate" checked={customerType==='corporate'? true:""} radius="sm"
                                    size="xl" onClick={(e)=>setCustomerType(e.target.value)}>
                                    <IconBuildingSkyscraper />
                                    {t('general.corporate')}
                                </Chip>
                            </div>


                        </Chip.Group>
                        { customerType=='corporate' ?
                        <div className='w-[60%] mx-auto '>
                           
                            <Input className="mt-8" placeholder={t('general.corporate_name')} value={corporateName}
                                name="name" onChange={(e)=>setCorporateName(e.target.value)}
                            required
                            />
                            <Input className="mt-2" type='email' placeholder={t('general.corporate_email')}
                                value={corporateEmail} name="email" onChange={(e)=>setCorporateEmail(e.target.value)}
                            required
                            />
                        </div>
                        :<></>
                        }
                        <div className='w-[60%] mx-auto '>

                            <div className='flex flex-rows justify-between mt-8'>
                                <Button variant="default" onClick={prevStep}>Back</Button>
                                <Button type='submit'>Next step</Button>

                            </div>
                        </div>
                    </form>



                </Stepper.Step>
                <Stepper.Step label={t('general.selectEle')} description={t('general.selectEleDesc')}>
                    <form className='min-h-[40vh] w-[60%] mx-auto' onSubmit={handleSubmitSecondStep}>
                        {/*Categories*/}
                        {categories &&
                        <>
                            <Select label={t('general.categories')} placeholder="Pick one" value={categoryId}
                                data={categories.map(category=> ({
                                value: category.id,
                                label: category['title_' + i18n.language]
                                }))}
                                onChange={changeCategory} required
                                error={categoryErr}
                                />
                                {categoryId !==null?

                                <>
                                    <Select label={t('general.types')} placeholder="Pick one"
                                        data={selectedCategory.types.map((type)=>
                                        ({
                                        value: type.id,
                                        label: type['title_'+i18n.language]
                                        }))}
                                        onChange={changeType} required
                                        error={typeErr}
                                        value={typeId}
                                        />
                                        {typeId !==null ?
                                        <Select label={t('general.equipment')} placeholder="Pick one"
                                            data={selectedCategory.types.find(type=> type.id ===
                                            typeId).equipment.map((equipment)=> ({
                                            value: equipment.id,
                                            label: equipment['title_'+i18n.language]
                                            }))}
                                            onChange={changeEquip} required
                                            error={equipErr}
                                            value={equipmentId}
                                            />:<></>}
                                            <Select label={t('general.finishes')} placeholder="Pick one"
                                                data={selectedCategory.finishes.map((finish)=>
                                                ({
                                                value: finish.id,
                                                label: finish['title_'+i18n.language]
                                                }))}
                                                onChange={changeFinish} required
                                                error={finishErr}
                                                value={finishId}
                                                />
                                </>

                                :<></>}
                        </>
                        }
                        <NumberInput  precision={2}
 placeholder={t('general.area')} label={t('general.area')} required value={area}
                            onChange={setArea} />
                        <NumberInput       precision={2}
 placeholder={t('general.depth')} label={t('general.depth')} value={depth} required
                            onChange={setDepth} />
                        <div className='w-full mx-auto '>

                            <div className='flex flex-rows justify-between mt-8'>
                                <Button variant="default" onClick={prevStep}>Back</Button>
                                <Button type='submit'>Next step</Button>

                            </div>
                        </div>
                    </form>

                </Stepper.Step>
                <Stepper.Step label={t('general.totalOffer')}>
                    <div className='mx-auto block text-center bg-white-1  rounded'>
                        <h3 className='mb-0 font-medium'>{t('general.totalPrice')}</h3>
                        <h2 className='mt-0 font-normal text-blue-1'>{price} EGP</h2>

                    </div>
                    { ready &&
                    <Table className='w-[60%] mx-auto ' highlightOnHover>
                       
                        <tbody>
                            <tr>
                                <td >{t('general.category')}</td>
                                <td className='font-medium text-right'>{selectedCategory['title_'+i18n.language]}</td>
                            </tr>
                            <tr>
                            <td >{t('general.type')}</td>
                            <td className='font-medium text-right'>{selectedType['title_'+i18n.language]}</td>
                            </tr>
                            <tr>
                            <td >{t('general.finish')}</td>
                            <td className='font-medium text-right'>{selectedFinish['title_'+i18n.language]}</td>
                            </tr>
                            <tr>
                            <td >{t('general.equipment')}</td>
                            <td className='font-medium text-right'>{selectedEquip['title_'+i18n.language]}</td>
                            </tr>
                            <tr>
                            <td >{t('general.area')}</td>
                            <td className='font-medium text-right ordinal'>{area}<span className='ordinal'>m2</span></td>
                            </tr>
                            <tr>
                            <td >{t('general.depth')}</td>
                            <td className='font-medium text-right'>{depth}m</td>
                            </tr>
                        </tbody>
                    </Table>
                } 
<div className=' mx-auto w-[60%]'>

<div className='flex flex-rows justify-between mt-8'>
    <Button variant="default" onClick={prevStep}>Back</Button>
    <Button loading={loader} onClick={sendOffer}>Save</Button>

</div>
</div>

                </Stepper.Step>
                {/* <Stepper.Completed>
                    Completed, click back button to get to previous step
                </Stepper.Completed> */}
            </Stepper>

            {/* <Group position="center" mt="xl">
                <Button variant="default" onClick={prevStep}>Back</Button>
                <Button onClick={goToNextStep}>Next step</Button>
            </Group> */}
        </Modal>

        <Button variant="white" className='mt-4 text-black-0' radius="xs"
            onClick={openGetOffer}>{t('general.getOffer')}</Button>
</>


);
}

export default GetOffer;
