import { Accordion, Button, Center, Checkbox, Modal } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import Payments from "./Payments";
import { useContext, useState } from "react";
import { AuthContext } from "../hooks/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ServiceTable from "./ServiceTable";

const ServicesTab = ({services}) => {

    const { t, i18n } = useTranslation();
    const { user } = useContext(AuthContext);

    return ( 
        <>{
            Object.values(services).map((service, index) => (
                <Accordion.Item value={service.service.title_en+index} key={index}>
                <Accordion.Control>
                    <div className='ar-dir'>
                    <span className='text-sm'><IconCalendar stroke={2} size={12}/> {service.formatted_created_at}</span>
                    <br/>
                   <span className='text-blue-1'>{service.service['title_'+i18n.language]}</span> 
                   </div>
                    </Accordion.Control>
                <Accordion.Panel>
                    
                <ServiceTable service={service}/>                   
    
                        { service.payments.length !== 0 ?
                       
                       <Payments offer={service} />
                       
                        :<></>
                            }
                 
                    </Accordion.Panel>
                
                
                </Accordion.Item> 
                 ))
    
                }        </>



     );
}
 
export default ServicesTab;