import { Button, Center, Container, Input, Loader, Modal, NumberInput, Select, Textarea } from '@mantine/core';
import useFetch from '../hooks/useFetch';
import { useTranslation } from "react-i18next";
import { useDisclosure } from '@mantine/hooks';
import { useContext, useState } from 'react';
import { IconLocation } from '@tabler/icons-react';
import { IconMapPin } from '@tabler/icons-react';
import axios from 'axios';
import { notifications } from '@mantine/notifications';
import { AuthContext } from '../hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Carousel } from '@mantine/carousel';

const Services = () => {

    const [serviceId,setSeriveId]=useState(null)
    const [categoryId,setCategoryId]=useState(null);
    const [categoryErr,setCategoryErr]=useState(false);
    const [area,setArea]=useState('');
    const [depth,setDepth]=useState('');
    const [locationLink,setLocationLink]=useState('');
    const [address,setAddress]=useState('');
    const [loader, setLoader] = useState(false);

    const { data: services ,isPending } = useFetch("services");
    const { data: categories } = useFetch("categories");
    const [opened, { open, close }] = useDisclosure(false);
    const { user } = useContext(AuthContext);
    const navigate=useNavigate();

    const { t, i18n } = useTranslation();
    const openModal=(e,service)=>{
        setSeriveId(service.id)
        if (!user){
            navigate('/login')
        }
        else {
            if(user.phone==null){
                navigate('/phone');
    
            }
            else {
                open()
    
            }
        }
    }
    const serviceRequest=(e)=>{
        e.preventDefault();
        setLoader(true)
        axios.post(process.env.REACT_APP_BACKEND_URL+"/api/add-service-request",{
          category_id:categoryId,
          service_id:serviceId,
          address:address,
          location:locationLink,
          area: area,
          depth: depth,
            },{
            headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${user['auth_token']}`
            },
            
            }) 
            .then(function (response){
                setLoader(false)
                notifications.show({
                  title: t('general.serviceRequestAdded'),
                  message: t('general.waitUpdates'),
                  color: 'teal',
          
                })
                setCategoryId(null)
                setArea('')
                setDepth('')
                setAddress('')
                setSeriveId(null)
                setLocationLink('')
                close();
            })
        
    }
    return ( 
        <>
        <Container size={"lg"} className='mt-14'>
        <h2 className='ar-dir'>{t('general.services')}</h2>

        {isPending ? 
    
    ( <Center>
     <Loader/>
     </Center>)
     :
        <Carousel       slidesToScroll={3}
 slideSize="33.3333334%" 
 breakpoints={[
    { maxWidth: 'md', slideSize: '50%' },
    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
  ]}
 
 align="start" slideGap="sm" controlsOffset="xs" loop dragFree>
       
       
       
        { services &&
            Object.values(services).map((service, index) => (
                <Carousel.Slide key={index}>

               <div onClick={(e)=>openModal(e,service)} key={index} className='cursor-pointer h-[50vh] w-full service-card rounded overflow-hidden' style={{backgroundImage:`url(${process.env.REACT_APP_BACKEND_URL+'/storage/'+service.cover_img})`}}>
                    <div className='w-full h-full service-card-overlay flex justify-center items-end'>
                    <h3 className='font-normal  mb-4  text-white-0	'>{service['title_'+i18n.language]}</h3>
                    </div>
               </div>
                
               </Carousel.Slide>
     
            ))
        }

</Carousel>
}
        </Container>

        <Modal  opened={opened} onClose={close} title={t('general.serviceRequest')} centered>
        <form className='ar-dir min-h-[40vh] w-[80%] mx-auto' onSubmit={serviceRequest}>
                        {/*Categories*/}
                        {categories &&
                        <>
                            <Select clearable label={t('general.categories')} placeholder="Pick one" value={categoryId}
                                data={categories.map(category=> ({
                                value: category.id,
                                label: category['title_' + i18n.language]
                                }))}
                                onChange={setCategoryId} 
                                 className='mb-2'
                                />
                               
                        </>
                        }
                        <NumberInput placeholder={t('general.area')} label={t('general.area')} required value={area}
                            onChange={setArea} precision={2} className='mb-2' />
                        <NumberInput placeholder={t('general.depth')}
                              precision={2}

                        label={t('general.depth')} value={depth} 
                            onChange={setDepth} className='mb-2' />
                        <Input.Wrapper
                        className='mb-2'
                                   label={t('general.location')}>
                            <Input name='location' value={locationLink}
                                  icon={<IconMapPin />}

                            onChange={(e)=>setLocationLink(e.target.value)} placeholder={t('general.location')}
                           
                           />
                            </Input.Wrapper>
                        <Textarea
                        placeholder={t('general.address')} label={t('general.address')}
value={address}
onChange={(e)=>setAddress(e.target.value)}
                           required className='mb-2'
                            />
                        <div className='w-full mx-auto '>
                            <Center>
                            <Button loading={loader} type='submit' className='capitalize mt-2 px-8'>{t('general.save')}</Button>
                            </Center>
                        </div>
                    </form>
      
      
      
      </Modal>
        </>


     );
}
 
export default Services;