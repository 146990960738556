import { ActionIcon, Badge, Button, LoadingOverlay, Modal, Popover, Table } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircle, IconX } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useContext, useState } from "react";
import { AuthContext } from "../hooks/AuthContext";
import { Navigate, useNavigate } from 'react-router-dom';

const Payments = ({offer}) => {
    const { user } = useContext(AuthContext);
    const [opened, { open, close }] = useDisclosure(false);
    const { t, i18n } = useTranslation();
    const [visible, { toggle }] = useDisclosure(false);
    const navigate=useNavigate();

    const paymob= async(payment)=>{
        if(user.phone==null){
navigate('/phone');
        }
        else {
    toggle()
        try {
    
            const response = await axios.post('https://accept.paymob.com/api/auth/tokens',{
                api_key: process.env.REACT_APP_PAYMOB_API_KEY
                },{
                headers: {
                'Content-Type': "application/json"
                },
                
                }); 
    
            const token=response.data.token
            const response2 = await axios.post('https://accept.paymob.com/api/ecommerce/orders',{
                    auth_token: token,
                    "delivery_needed": "false",
                    "amount_cents": (payment.value*100),
                    "currency": "EGP",
                    "merchant_order_id":payment.id+'-'+Math.floor(10000000 + Math.random() * 90000000)+Date.now(),
                    "items": [
                      {
                         "id":payment.id,
                          "name": "Offer",
                          "amount_cents": (payment.value*100),
                          "description": "Offer",
                          "quantity": "1"
                      }
                      ],
                   
                    },{
                    headers: {
                    'Content-Type': "application/json"
                    },
                    
                    })
                    // .then((response)=>{
                    //         console.log(response)
                    // }).catch((err)=>{
                    //     console.log(err)

                    // }) ;
            const id=response2.data.id
            await axios.post('https://accept.paymob.com/api/acceptance/payment_keys',{
                auth_token: token,
                "amount_cents": (payment.value*100), 
                "expiration": 3600, 
                "order_id": id,
                "billing_data": {
                    "apartment": "24", 
                    "email": user.email, 
                    "floor": "6", 
                    "first_name": user.name, 
                    "street": "6", 
                    "building": "18", 
                    "phone_number":  user.phone, 
                    "shipping_method": "nan", 
                    "postal_code": "01898", 
                    "city": "new cairo", 
                    "country": "EG", 
                    "last_name": user.name, 
                    "state": "cairo"
                }, 
                "currency": "EGP", 
                "integration_id": process.env.REACT_APP_PAYMOB_INTEGRATION_ID,
               
                },{
                headers: {
                'Content-Type': "application/json"
                },
                
                }).then(function (response){
                    window.location.href = `https://accept.paymob.com/api/acceptance/iframes/837328?payment_token=${response.data.token}`;
                    toggle()
                })
         
            // Extract relevant data from the second response
          
          } catch (error) {
            console.error('Error:', error.message);
          }
        }}

    return ( 
        <>
        <div className='grid grid-cols-2 gap-4'>
                <div className='  bg-blue-2 text-white-0 px-8 rounded'>
                    <h2>{t('general.cost')}</h2>
                    <h3>{offer.total_price} EGP</h3>
                </div>
                <div className=' bg-blue-3 text-white-0 px-8 rounded'>
                    <h2>{t('general.paid')}</h2>
                    <h3>{(offer.payments.filter(obj => obj['paid'] === 1).reduce((sum, obj) => sum + obj['value'], 0))} EGP</h3>
                </div>
        </div>
        <div className='flex justify-between items-center bg-yellow-0 text-white-0 mt-4 py-2 px-8 rounded'>
                   <div>
                   <h2>{t('general.remaining')}</h2>
                    <h3>{offer.total_price-(offer.payments.filter(obj => obj['paid'] === 1).reduce((sum, obj) => sum + obj['value'], 0))} EGP</h3>
                   </div>
                   <Button className='w-1/5' variant="default" color="dark" onClick={open} >{t('general.pay')}</Button>
                </div>


                <Modal opened={opened} onClose={close} title={t('general.payments')} centered>
                <LoadingOverlay visible={visible} overlayBlur={2} />

                <Table className=' mx-auto ' highlightOnHover>
<thead>
    <tr>
        <th>{t("general.amount")}</th>
        <th>{t("general.dueDate")}</th>
        <th>{t("general.paid")}</th>
        <th></th>
    </tr>
</thead>
                <tbody>

           {Object.values(offer.payments).map((payment,index)=>(
                <tr key={index}>
                <td >{payment.value} EGP</td>
                <td >{payment.formatted_due_date}</td>
                <td >{
                payment.paid===1 ?
                 <ActionIcon color="teal" radius="xl" variant="filled">
                 <IconCheck size="1.125rem" />
               </ActionIcon>
               :
               <ActionIcon color="red" radius="xl" variant="filled">
               <IconX size="1.125rem" />
             </ActionIcon>
            
            }</td>
                <td>
                    {payment.paid===0 ?
                <Button  onClick={(e)=>paymob(payment)}>{t('general.paynow')}</Button>
                  : 
                  <Popover  position="bottom" withArrow shadow="md">
                  <Popover.Target>
                    <ActionIcon color="blue" radius="xl" variant="filled">
                        <IconInfoCircle/>
                        </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <p>Payment ID: {payment.ref_id}</p>
                    <p>Paid at: {payment.formatted_paid_at}</p>

                  </Popover.Dropdown>
                </Popover>
            
            }
                </td>

                {/* <td className='font-medium text-right ar-text-left'>{offer.type.category['title_'+i18n.language]}</td> */}
            </tr>

           ))}
        </tbody>

            </Table>
                 </Modal>

        </>


     );
}
 
export default Payments;