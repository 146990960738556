import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { MantineProvider } from '@mantine/core';
import { AuthProvider } from "./hooks/AuthContext";
import { Notifications } from '@mantine/notifications';

import './i18n';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
            <MantineProvider withGlobalStyles withNormalizeCSS
            theme={{
              // primaryShade: 3 ,
              colors: {
                myblue: ['#dafcff','#b3edfa','#88e1f3','#5cd3ec','#33c6e6','#19adcc','#0886a0','#006074','#003b47','#00151c'],
              },

            }}
            >
            <Notifications position="top-right" />

            <AuthProvider>

     <Router>
      <App />
    </Router>
    </AuthProvider>

    </MantineProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
