import { Accordion, Center, Container, Loader, Tabs } from '@mantine/core';
import useFetch from '../hooks/useFetch';
import { useTranslation } from "react-i18next";
import {  useEffect } from 'react';
import { notifications } from '@mantine/notifications';
import OffersTab from '../components/OffersTab';
import ServicesTab from '../components/ServicesTab';

const Offers = () => {
   
   
    const { t, i18n } = useTranslation();

    const { data: offers , isPending:isPendingOffers} = useFetch("get-offers");
    const { data: services , isPending:isPendingServices } = useFetch("get-service-requests");

   
    useEffect(() => {
        // Function to get query parameters
        const getQueryParam = (param) => {
          const searchParams = new URLSearchParams(window.location.search);
          return searchParams.get(param);
        };
  // Get specific query parameters
  const id = getQueryParam('id');
  const success=getQueryParam('success');
if(getQueryParam('success')==='true'){
    notifications.show({
        title: 'Payment Succeded',
        message: 'Wait for our updates',
        color: 'teal',

      })
}
else if(getQueryParam('success')==='false'){
    notifications.show({
        title: 'Payment faild',
        message: 'Contact us for more',
        color: 'red',

      })
}
 
}, []); // The empty dependency array ensures that this effect runs once when the component mounts.

 


    return (
        <Container size={"lg"} className=" my-8 min-h-[60vh]">
                <h1>{t('general.offers')}</h1>
                <Tabs defaultValue="offers" color='myblue.3'>
                <Tabs.List grow position="center">
                    <Tabs.Tab value="offers">{t('general.offers')}</Tabs.Tab>
                    <Tabs.Tab value="services">{t('general.services')}</Tabs.Tab>

                </Tabs.List>
                <>
            <Accordion variant="contained" >
            <Tabs.Panel value="offers" pt="xs">
            {isPendingOffers ? 
            (
            <Center className='py-12'>
            <Loader/>   
            </Center>)
           
         
           :
           (offers && offers.length >0 ?
           <OffersTab offers={offers}/>
           :
           <>{t('general.noOffers')}</>
           
           )

        }
      </Tabs.Panel>

      <Tabs.Panel value="services" pt="xs">
      {isPendingServices ? 
            (      <Center className='py-12'>
            <Loader/>   
            </Center>    
            ):
            (services && services.length >0 ?
            <ServicesTab services={services}/>
            :
            <>{t('general.noServicesRequests')}</>
            
            )
            
         
           

        }      </Tabs.Panel>
            

            



          
            </Accordion>

            </>
            </Tabs>

        </Container>
    );
}
 
export default Offers;