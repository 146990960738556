import { Accordion, Badge, Button, Table } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AuthContext } from "../hooks/AuthContext";
import { useContext, useState } from "react";
import { notifications } from '@mantine/notifications';
import axios from "axios";
import { Navigate, useNavigate } from 'react-router-dom';

const OfferTable = ({offer}) => {

    const { t, i18n } = useTranslation();
    const { user } = useContext(AuthContext);
    const [loader, setLoader] = useState(false);
    const [requestedMaintId,setRequestMaintId]=useState(null)
    const navigate=useNavigate();



const requestMaintenance=(e,id)=>{
    setLoader(true)

    axios.post(process.env.REACT_APP_BACKEND_URL+"/api/add-maintenance-request",{
        offer_id: id,
          },{
          headers: {
          'Content-Type': "application/json",
          Authorization: `Bearer ${user['auth_token']}`
          },
          
          }) 
          .then(function (response){
              setLoader(false)

             notifications.show({
                title: t('general.maintenanceRequested'),
                color: 'teal',
        
              })
              setLoader(false)
              e.target.disabled = true;
navigate(1)
setRequestMaintId(id)
          })

    
   
}
    return ( 
<>
        <Table className=' mx-auto ar-dir' highlightOnHover>
                       
        <tbody>
            <tr>
                <td >{t('general.category')}</td>
                <td className='font-medium text-right ar-text-left'>{offer.type.category['title_'+i18n.language]}</td>
            </tr>
            <tr>
            <td >{t('general.type')}</td>
            <td className='font-medium text-right ar-text-left'>{offer.type['title_'+i18n.language]}</td>
            </tr>
            <tr>
            <td >{t('general.finish')}</td>
            <td className='font-medium text-right  ar-text-left'>{offer.finish['title_'+i18n.language]}</td>
            </tr>
            <tr>
            <td >{t('general.equipment')}</td>
            <td className='font-medium text-right  ar-text-left'>{offer.equipment['title_'+i18n.language]}</td>
            </tr>
            <tr>
            <td >{t('general.area')}</td>
            <td className='font-medium text-right ordinal ar-text-left'>{offer.area}<span className='ordinal'>m2</span></td>
            </tr>
            <tr>
            <td >{t('general.depth')}</td>
            <td className='font-medium text-right ar-text-left'>{offer.depth}m</td>
            </tr>

            <tr>
            <td >{t('general.status')}</td>
            <td className='font-medium text-right ar-text-left'>
              <Badge color={offer==='reviewing' ? 'cyan':(offer==='approved'?'green':(offer==='rejected'?'red':'teal'))} >
              {t('general.'+offer.status)}</Badge>
                </td>
            </tr>
            <tr>
            <td >{t('general.progress')}</td>
            <td className='font-medium text-right ar-text-left'>
            <Badge color={offer.progress==='reviewing' ? 'cyan':(offer.progress==='approved'?'green':(offer.progress==='rejected'?'red':'teal'))} >

                 {t('general.'+offer.progress)}
                 </Badge>
                 </td>
            </tr>
            {offer.attachment &&
            <tr>
            <td >{t('general.attachment')}</td>
            <td className='font-medium text-right ar-text-left'><Link to={offer.attachment} target="_blank"><IconDownload color="#23C2E4" /></Link></td>
            </tr>
            }
            <tr><td></td><td></td></tr>
         {offer.progress=='completed' &&
            <tr>
            <td>
            <h3 className="text-md">{t('general.requestMaintenance')}</h3></td>
            <td className='font-medium text-right ar-text-left'>
                <Button 
                loading={loader} 
                onClick={(e)=>requestMaintenance(e,offer.id)}
                disabled={requestedMaintId==offer.id ? true:false}
                >
                    {requestedMaintId==offer.id ? t('general.requested'):t('general.requestNow')}
                    
                    </Button>
                </td>
            </tr>
            }
       
       
        </tbody>
    </Table>
    {offer.maintenances.length>0 ?
    <Accordion defaultValue="customization">
      <Accordion.Item value="focus-ring">
        <Accordion.Control>{t('general.maintenance')}</Accordion.Control>
        <Accordion.Panel>

            <Table className="text-left">
            <tr>
<th>{t('general.startingDate')}</th>
<th>{t('general.status')}</th>
<th>{t('general.cost')}</th>


</tr>

                {offer.maintenances.map((item,index)=>(
                    <tr>
<td>{item.date ? item.date:t('general.notYetDetermined')}</td>
<td>
<Badge color={item.status==='reviewing' ? 'cyan':(item.status==='approved'?'green':(item.status==='rejected'?'red':'teal'))} >

    {item.status ? item.status:t('general.notYetDetermined')}
    </Badge>
    </td>
<td>{item.total_price ? item.total_price:t('general.notYetDetermined')}</td>

</tr>
                ))}

                
            </Table>



        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
:<></>}
    </>
     );
}
 
export default OfferTable;