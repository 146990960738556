import Banners from "../components/Banners";
import Categoriescards from "../components/Categoriescards";
import Services from "../components/Services";

const Home = () => {
    return ( 
<div className="pb-[80px]">

<Banners />
<Categoriescards />
<Services />

</div>

     );
}
 
export default Home;