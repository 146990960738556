import logo from './logo.svg';
import whatsapp from './imgs/whats.png';

import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { useContext, useEffect, useState } from "react";
import {  useTranslation } from "react-i18next";
import Category from './pages/Category';
import Type from './pages/Type';
import Project from './pages/Project';
import Login from './pages/Login';
import { AuthContext } from './hooks/AuthContext';
import Signup from './pages/Signup';
import Offers from './pages/Offers';
import { messaging } from './firebase';
import { getMessaging, onMessage } from "firebase/messaging";
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import Contact from './components/Contact';
import Policies from './pages/Policies';
import Phone from './pages/Phone';
import CompletedIffers from './pages/CompletedOffers';
import Settings from './pages/Settings';
import Help from './pages/Help';

function App() {
const [indDisabled,setIndDisabled]=useState(true)
const [opened, { toggle, close }] = useDisclosure(true);




const handleIncomingMessage = (payload) => {
  notifications.show({
    title: payload.notification.title,
    message: payload.notification.body,
    color: 'teal',
  });
  setIndDisabled(false);
};

useEffect(() => {
  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        onMessage(messaging, handleIncomingMessage);
      } else if (permission === 'denied') {
        console.warn('Notification permission denied');
      } else if (permission === 'default') {
        console.warn('Notification permission request dismissed');
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };

  requestNotificationPermission();
}, []);


// useEffect(() => {
// onMessage(messaging, (payload) => {
//   notifications.show({
//     title: payload.notification.title,
//     message: payload.notification.body,
//     color: 'teal',

//   })
//   setIndDisabled(false)
// });
// }, []);

// useEffect(()=>{
//   Notification.requestPermission()
// },[])
 
  const {i18n} = useTranslation();
  const {user}=useContext(AuthContext)

  


  const [links, setLinks] = useState([
    { link: "/", label: "home", btn: 0, hash: 1 },
    { link: "/Offer", label: "offer", btn: 0, hash: 0 },
    { link: "/help", label: "help", btn: 0, hash: 1 },
    { link: "/settings", label: "settings", btn: 0, hash: 0 },
  ]);

  return (
    <div className={`App ${i18n.language}`} 
    // dir={i18n.language==='ar'?'rtl':''}
    
    >
      <header className="App-header">
      {/* <Dialog opened={opened} withCloseButton onClose={close} size={'xs'} radius="lg">
       <Image src={whatsapp} maw={100}/>
      </Dialog> */}
<Contact />
     

        <div className="content">
          <Navbar links={links} indDisabled={indDisabled} setIndDisabled={setIndDisabled} />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/our-policies" element={<Policies />}></Route>
            <Route exact path="/help" element={<Help />}></Route>
            <Route exact path="/phone" element={!user ? <Login />:<Phone />}></Route>
            <Route exact path="/settings" element={!user ? <Login />:<Settings />}></Route>

            <Route exact path="/login" element={user ?<Home />: <Login />}></Route>
            <Route exact path="/sign-up" element={user ?<Home />:<Signup />}></Route>
            <Route exact path="/offer" element={!user ? <Login />:<Offers />}></Route>
            <Route exact path="/completed-offers" element={!user ? <Login />:<CompletedIffers />}></Route>

            {/* <Route exact path="/payment-callback" element={<Test />}></Route> */}

            <Route
              exact
              path="/category/:id/:title"
              element={<Category />}
            ></Route>
            <Route
              exact
              path="/type-projects/:id/:title"
              element={<Type />}
            ></Route>
              <Route
              exact
              path="/project/:id/:title"
              element={<Project />}
            ></Route>
          </Routes>
          {/* <div style={{ height: "90px" }}></div> */}
          <Footer links={links}/>
        </div>
      </header>
    </div>
  );
}

export default App;
