import { useParams } from "react-router-dom";
import Categoriesbar from "../components/Categoriesbar";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Center, Container, Loader } from "@mantine/core";

const Category = () => {

  const { id  } = useParams();
  const { t, i18n } = useTranslation();

  const { data: category , isPending} = useFetch("category/"+id);

    return ( 

       <>
       <Categoriesbar catid={id}  />
<Container size={"lg"} className="pb-[80px] ar-dir">



{category ? (
  <>
    <h3 className='mt-12 font-medium'>{category['title_' + i18n.language]} {t('general.types')}</h3>
    {isPending ? (
      <Center>
        <Loader />
      </Center>
    ) : (
      category.types && category.types.length > 0 ? (
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
          {category.types.map((type, index) => (
            <Link to={`/type-projects/${type.id}/${type.title_en}`} key={index} className={`h-[30vh] overflow-hidden text-white-0 rounded type-card bg-cover bg-center no-underline`} style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL+'/storage/'+type.img})` }}>
              <div className='h-full px-8 py-4 w-full type-overlay-div flex items-end'>
                <h3 className='font-normal mb-0'>{type['title_'+i18n.language]}</h3>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <p>{t('general.noTypes')}</p>
      )
    )}
  </>
) : (
  <div className='flex justify-center'>
    <Loader size="sm"/>
  </div>
)}

</Container>

       </>

     );
}
 
export default Category;