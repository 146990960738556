import useFetch from '../hooks/useFetch';
import { useTranslation } from "react-i18next";
import { ActionIcon, Button, Container, Image, Loader, Skeleton } from '@mantine/core';
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import {  useState } from 'react';


const Categoriesbar = ({catid}) => {

    const { data: categories } = useFetch("categories");
    const { t, i18n } = useTranslation();
const [active,setActive]=useState(catid);

    return ( 
        <>
        <Container size={"lg"} className=" mt-8 ar-dir">
        <h2>{t('general.categories')}</h2>

<div className='w-full grid grid-cols-3 lg:grid-cols-6 gap-4 text-white-1  justify-between'>
 { categories &&
            Object.values(categories).map((category, index) => (
                            <Link to={'/category/'+category.id+'/'+category.title_en} key={index} className={` px-4 py-2 rounded category-card	no-underline cursor-pointer`} style={{backgroundColor:active==category.id ? 'white' : category.bg_color,color:active==category.id ? category.bg_color:'white',border:active==category.id ? `3px solid ${category.bg_color}`:''}}   onClick={(e)=>setActive(category.id)}>

                 <div >
                <h4 className='font-normal  mb-0 pb-0 	'>{category['title_'+i18n.language]}</h4>

                </div>  

                </Link>
                
                        
            ))
        }
</div>




</Container>


</>
     );
}
 
export default Categoriesbar;