import useFetch from '../hooks/useFetch';
import { useTranslation } from "react-i18next";
import { Button, Center, Container, Image, Loader } from '@mantine/core';
import parse from "html-react-parser";
import { Link } from 'react-router-dom';


const Categoriescards = () => {

    const { data: categories, isPending } = useFetch("categories");
    const { t, i18n } = useTranslation();

    return ( 
        <>
        <Container size={"lg"} className=" mt-8 ar-dir">
        <h2>{t('general.categories')}</h2>
        {isPending ? 
    
    ( <Center>
     <Loader/>
     </Center>)
     :
<div className='w-full grid grid-cols-3 lg:grid-cols-6  gap-4 text-white-1  justify-between'>
 { categories &&
            Object.values(categories).map((category, index) => (
               
                 <div key={index} className=' px-4 py-4 rounded category-card	 cursor-pointer		 ' style={{backgroundColor:category.bg_color}}>
             <Link to={'/category/'+category.id+'/'+category.title_en} className='text-white-1 no-underline'>
             <div className='flex justify-end h-2/4 '>              
                 <Image width={60} className='' src={process.env.REACT_APP_BACKEND_URL+'/storage/'+category.icon} />
            </div>
                
                <h4 className='font-normal pt-2 mb-0 pb-0 	'>{category['title_'+i18n.language]}</h4>

                </Link>
                </div>  
                
                        
            ))
        }
</div>
}
</Container>


</>
     );
}
 
export default Categoriescards;