import { Button, Container, Divider, Image, Input, LoadingOverlay, PasswordInput } from "@mantine/core";
import login from "../imgs/login.png";
import gmail from "../imgs/gmail.svg";

import { IconBrandFacebook, IconBrandGoogle, IconLock, IconMail, IconPhone, IconUser } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import logo from "../imgs/logo.png";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../hooks/AuthContext";
import { useNavigate } from "react-router-dom";
import {LoginSocialGoogle,LoginSocialFacebook } from 'reactjs-social-login';
import { IconBrandFacebookFilled } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { IMaskInput } from 'react-imask';
import { dtoken } from "../firebase";


const Signup = () => {
    const [loader, setLoader] = useState(false);
    const { t, i18n } = useTranslation();
    const [email,setEmail]=useState('');
    const [name,setName]=useState('');
    const [phone,setPhone]=useState('');

    const [password,setPassword]=useState('');
    const [passwordConfirm,setPasswordConfirm]=useState('');

    const [err,setErr]=useState(true)
    const [errType,setErrType]=useState("checkFields")

    const { authenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const onLoginStart = useCallback(() => {
        // alert('login start');
      }, []);
      const [provider, setProvider] = useState('');
      const [profile, setProfile] = useState();
      const REDIRECT_URI = 'https://horizonswimmingpools.com/sign-up';

    const googleLogin=(data)=>{
        setLoader(true)
            axios.post(process.env.REACT_APP_BACKEND_URL+"/api/auth/loginSocial",{
                email:data.email,
                social_type:"google",
                social_id:data.sub,
                name:data.name,
                photo:data.picture
                },{
                headers: {
                Accept: "application/json",
                // "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
                },
                
                }) 
                .then(function (response){
                    // console.log(response.data)
                   saveUser(response.data.data)
        
                })
        
        }
        
        
        const saveUser=async(data)=>{
          localStorage.setItem('auth_token',data['auth_token'])
          localStorage.setItem('user',JSON.stringify(data))
        
          Notification.requestPermission()
          const currentToken= await dtoken
            if (currentToken) {
              axios.post(process.env.REACT_APP_BACKEND_URL+"/api/auth/edit",{
                'device_token':currentToken
              },{
                headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${data['auth_token']}`
                },
                
                })          
              //  console.log(currentToken)
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              // ...
            }
          authenticated();
          navigate(1);
          setLoader(false)
        
        }
        
        const signupAttempt=(e)=>{
            e.preventDefault();

            if(password!==passwordConfirm){
                setErr(false)
                setErrType('matchPass')

            }
            else {
                setErr(true)
        
                setLoader(true);
            
            axios.post(process.env.REACT_APP_BACKEND_URL+"/api/auth/signup",{
            email:email,
            password:password,
            password_confirmation:passwordConfirm,
            name:name,
            phone:phone,
            social_id:null,
            social_type:"local"
    
    
            },{
            headers: {
            Accept: "application/json",
            // "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
            
            }) 
            .then(function (response){

                if(response.data.status==true){
                    saveUser(response.data.data)
                    // console.log(response.data)
                }
                else {
                    setLoader(false)
                    setErr(false)
                    setErrType("validEmail")
                }
            
            })
            
            
            }
           
        }




    return ( 

        <> <div className="bg-white-2">
        <Container className="py-12" size={'sm'}>
            <div className="grid grid-cols-1 lg:grid-cols-2 rounded overflow-hidden">
                <img src={login} alt="" className="w-full lg:block hidden" />
                <div className="bg-white-0 flex items-center w-full lg:py-0 py-8">
                    <div className="w-full mx-4 relative">
                    <LoadingOverlay visible={loader} overlayBlur={2} />
<h3 className="text-center font-normal">{t("general.create_an_account")}</h3>
                        <form className="w-full mt-8 login-form" onSubmit={signupAttempt}>
                        <Input className="w-full" icon={<IconUser />}
                            placeholder={t('general.fullName')}
                            value={name}
                            name="name"
                            onChange={(e)=>setName(e.target.value)}
                            required
                            />
                           
                              <Input className="mt-4 w-full" icon={<IconMail />}
                            placeholder={t('general.email')}
                            value={email}
                            name="email"
                            type="email"

                            onChange={(e)=>setEmail(e.target.value)}
                            required
                            />
                             <Input className="mt-4 w-full" icon={<IconPhone />}
                            placeholder={t('general.phone')}
                            value={phone}
                            name="phone"
                            onChange={(e)=>setPhone(e.target.value)}
                            // component={IMaskInput}
                            // mask="+20 (000) 000-0000"
                            required
                            />
                            <PasswordInput className="mt-4 password-in" placeholder={t('general.password')} withAsterisk
                                icon={<IconLock />}
                            value={password}
                            name="password"
                            onChange={(e)=>setPassword(e.target.value)}
                            required
                            />
                             <PasswordInput className="mt-4 password-in" placeholder={t('general.confirmPassword')} withAsterisk
                                icon={<IconLock />}
                            value={passwordConfirm}
                            name="passwordConfirm"
                            onChange={(e)=>setPasswordConfirm(e.target.value)}
                            required
                            />
                            <h5 hidden={err} className="text-red-0 my-0 font-normal py-1 text-center">{t('general.'+errType)}</h5>
                            <Button type="submit" className="mt-4 w-full bg-blue-1">{t('general.sign_up')}</Button>
                        </form>
                        <Divider my="lg" label={t('general.or')} labelPosition="center" />

                        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GG_APP_ID || ''}
          onLoginStart={onLoginStart}
          scope="https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.email"

          redirect_uri={REDIRECT_URI}
        //   scope="openid profile email"
        //   discoveryDocs="claims_supported"
        //   access_type="offline"
          onResolve={({ provider, data }) => {
           googleLogin(data)
          }}
          onReject={err => {
            console.log(err);
          }}
        >

            <Button className="w-full" leftIcon={<Image src={gmail} className="mx-4" />}   variant="outline" color={'gray'}>{t('general.continueWithGoogle')}</Button>
   </LoginSocialGoogle>
   
                   
                   
                   <h5 className="text-center">{t('general.alreadyHaveAnAccount')} <Link to={"/login"} className="no-underline">{t('general.login')}</Link> </h5>

                    </div>



                </div>



            </div>


        </Container>

    </div></>
     );
}
 
export default Signup;