import { ActionIcon, Button, Container, Drawer, Flex, Image, Indicator, Loader, Menu, Notification, Popover } from "@mantine/core";
import logo from "../imgs/logo.png";
import { HashLink } from "react-router-hash-link";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {  Icon3dCubeSphere, IconBell, IconLogout2, IconMenu2, IconMessageCircle, IconSettings, IconUser } from "@tabler/icons-react";
import { AuthContext } from "../hooks/AuthContext";
import useFetch from "../hooks/useFetch";
import { useDisclosure } from "@mantine/hooks";
import NavbarMobile from "./NavbarMobile";
import { useNavigate } from 'react-router-dom';

const Navbar = ({ links,setIndDisabled,indDisabled }) => {
const { t, i18n } = useTranslation();
const changeLanguage = (language) => {
i18n.changeLanguage(language);
localStorage.setItem('locale', language)
};
const location = useLocation();
const [opened, { open, close }] = useDisclosure(false);

const { data: notifications } = useFetch("get-notifications");
const navigate=useNavigate();
  const { user, notAuthenticated } = useContext(AuthContext);
  const signOut=()=>{
    notAuthenticated()

  }
  const openCompProjects=()=>{
    navigate('/completed-offers')
  }
const [active, setActive]=useState(0)

return (
<div className="navbar-main py-4">
    <Container size={"lg"} className=" ">
        <div className="flex flex-row justify-between items-center" >
            <div className="">
              <Link to={'/'}> <Image src={logo} width={200} className=""/></Link> 
            </div>
            <div className="text-left lg:flex justify-between  gap-6 hidden">
                {links.map((link,index) => (

<div key={index}>
                <HashLink key={index} id={index} smooth onClick={(e)=>setActive(index)}
                    to={link.link}
                    className={`underline-offset-8 text-black-0 ${ location.pathname === link.link ? 'text-blue-1 underline':'no-underline'}`}
                    >
                    {t('general.'+link.label)}
                </HashLink>
                </div>
                ))}


            </div>
<div className="flex items-center">

    {user ? 
    <div className="lg:block hidden">
     <Menu shadow="md" classNames={'mx-4'} width={200}>
     <Menu.Target>
       <Button leftIcon={<IconUser size={'1rem'}/>} variant="white" color="dark" compact>{user.name}</Button>
     </Menu.Target>

     <Menu.Dropdown>
       {/* <Menu.Item icon={<IconSettings size={14} />}>Settings</Menu.Item> */}
       <Menu.Item onClick={openCompProjects}  icon={<Icon3dCubeSphere size={14} />}>
        {t('general.projects')}

        
        </Menu.Item>


       <Menu.Divider />

       {/* <Menu.Label>Danger zone</Menu.Label> */}
       <Menu.Item onClick={(e)=>signOut()} color="red" icon={<IconLogout2 size={14}/>}>{t('general.logOut')}</Menu.Item>
     </Menu.Dropdown>
   </Menu>

   
   </div>
    
    :<Link className="mx-4 no-underline text-black-0 flex items-center " to={'/login'}>
    <ActionIcon className="mx-1" color="blue" size="sm" radius="xl" variant="filled">

    <IconUser size={'0.8rem'} /> 

    </ActionIcon>
    Login
    
    </Link>}

<div className="lg:block hidden">
<HashLink className="no-underline	text-black-0"
onClick={() => changeLanguage('en')}
>En</HashLink>
{" "}/{" "}
<HashLink className="no-underline	text-black-0"
onClick={() => changeLanguage('ar')}
>Ar</HashLink>
</div>


{user && 

<Popover   position="bottom" withArrow shadow="md">
<Popover.Target onClick={(e)=>setIndDisabled(true)}>
<Indicator disabled={indDisabled} inline processing size={12} color="myblue.3">

<ActionIcon className="mx-2" color="myblue.3" variant="light">
  <IconBell size={20} />
 </ActionIcon>
 </Indicator></Popover.Target>
<Popover.Dropdown >
  <div className="max-h-[50vh] min-h-[10vh] overflow-auto 	">
{
  notifications ? 
  
  Object.values(notifications).map((notification,index)=>(
<div key={index}>
    <Notification withBorder withCloseButton={false} className="mb-2" key={index} title={notification.title}>
    {notification.body}
  </Notification>
  </div>
  
  )):<>
{t('general.noNotificationsYet')}  
  
  </>

  
}


</div>

</Popover.Dropdown>
</Popover>
   

   }
   
<div className="lg:hidden">
  <ActionIcon onClick={open}>
    <IconMenu2 />
  </ActionIcon>
</div>
</div>



        </div>



    </Container>

    <Drawer opened={opened}  size="50%" onClose={close} >
  <NavbarMobile user={user} links={links} signOut={signOut} changeLanguage={changeLanguage}/>
      </Drawer>

</div>


);
}

export default Navbar;
