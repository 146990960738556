import { Button, Input } from "@mantine/core";
import { IconPhone } from "@tabler/icons-react";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../hooks/AuthContext";
import { notifications } from '@mantine/notifications';
import { RecaptchaVerifier, signInWithPhoneNumber,getAuth } from "firebase/auth";
import { auth, db } from "../firebase";
import { Navigate, useNavigate } from 'react-router-dom';

const Phone = () => {
    const [phoneNumber,setPhoneNumber]=useState('');
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const { t, i18n } = useTranslation();
    const [otpCode, setOtpCode] = useState("");
    const [switchToOtp, setSwitchToOtp] = useState(false);
    const [otpError, setOtpError] = useState("");

    const [loader, setLoader] = useState(false);
    const { user } = useContext(AuthContext);
    const auth = getAuth();
    const navigate=useNavigate();

    
    const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'submit-phone', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      }
    });
};


    
    const requestOTP=(e)=>{
      e.preventDefault();

      setLoader(true)
      setPhoneNumberError('');

if(phoneNumber!=null && phoneNumber.length ==11){
  generateRecaptcha();

  let appVerifier = window.recaptchaVerifier;
  signInWithPhoneNumber(auth, '+2'+phoneNumber, appVerifier)
  .then((confirmationResult) => {
    setLoader(false)

    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    window.confirmationResult = confirmationResult;
    // ...
    setSwitchToOtp(true)
  }).catch((error) => {
    setPhoneNumberError(t('general.phoneNumberError'));
    setLoader(false)

  });

}
else {
  setPhoneNumberError(t('general.phoneNumberError'));
  setLoader(false)

}

    }

    const savePhone=(e)=>{
      e.preventDefault();

      if(phoneNumber!=null && phoneNumber.length ==11){

      user.phone=phoneNumber
      axios.post(process.env.REACT_APP_BACKEND_URL+"/api/auth/edit",{
        phone: phoneNumber,
          },{
          headers: {
          'Content-Type': "application/json",
          Authorization: `Bearer ${user['auth_token']}`
          },
          
          }) 
          .then(function (response){
              setLoader(false)

             notifications.show({
                title: t('general.phoneNumberSuccess'),
                color: 'teal',
        
              })
              setLoader(false)

              localStorage.setItem('user', JSON.stringify(user));

          })

      navigate('/');
    }
    else {
      setPhoneNumberError(t('general.phoneNumberError'));
      setLoader(false)
    
    }

    }
    const verifyOTP = (e) => {
      e.preventDefault();

      setOtpError('')

      setLoader(true)

      e.preventDefault();
      if (otpCode.length === 6) {
        let confirmationResult = window.confirmationResult;
        confirmationResult
          .confirm(otpCode)
          .then((result) => {
            user.phone=phoneNumber
            axios.post(process.env.REACT_APP_BACKEND_URL+"/api/auth/edit",{
              phone: phoneNumber,
                },{
                headers: {
                'Content-Type': "application/json",
                Authorization: `Bearer ${user['auth_token']}`
                },
                
                }) 
                .then(function (response){
                    setLoader(false)
 
                   notifications.show({
                      title: t('general.phoneNumberSuccess'),
                      color: 'teal',
              
                    })
                    setLoader(false)

                    localStorage.setItem('user', JSON.stringify(user));

                })

            navigate('/');
          })
          .catch((error) => {
            setOtpError(t('general.otpError'))
            setLoader(false)

          });
      }
else {
  setOtpError(t('general.otpError'))
  setLoader(false)

}
    }


    

    return ( 
     <>
        <div className={` lg:w-1/4 w-3/4 mx-auto drop-shadow-md	ar-right	 my-16 rounded p-4 flex justify-center h-[40vh] items-center bg-white-2 ${switchToOtp===true ? 'hidden':''}`}>
        <form  onSubmit={savePhone}>
         <Input.Wrapper label={t('general.phone')} className="ar-right"
               error={phoneNumberError}

         >
          <Input className="mt-4 w-full " icon={<IconPhone />}
                            placeholder={t('general.phone')}
                            value={phoneNumber}
                            name="phone"
                            onChange={(e)=>setPhoneNumber(e.target.value)}
                            required
                            />
                           </Input.Wrapper>

                           <Button id="submit-phone" type="submit" loading={loader} className="mt-4">{t('general.confirm')}</Button>
                            </form>
                            </div>

<div className={`lg:w-1/4 w-3/4 mx-auto drop-shadow-md	ar-right	 my-16 rounded p-4 flex justify-center h-[40vh] items-center bg-white-2 ${switchToOtp===true ? '':'hidden'}`}>
<form  onSubmit={verifyOTP}>
 <Input.Wrapper label={t('general.otpPrompt')} className="ar-right"
       error={otpError}

 >
  <Input className="mt-4 w-full " 
                    placeholder={t('general.otpPrompt')}
                    value={otpCode}
                    name="phone"
                    onChange={(e)=>setOtpCode(e.target.value)}
                    required
                    />
                   </Input.Wrapper>

                   <Button id="" type="submit" loading={loader} className="mt-4" >{t('general.confirm')}</Button>
                    </form>
                    </div>
                    </>
     );
}
 
export default Phone;