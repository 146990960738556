import useFetch from '../hooks/useFetch';
import { Container } from "@mantine/core";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Help = () => {
    const { data: info } = useFetch("info");
    const { t, i18n } = useTranslation();

    return ( 
<>
{info &&
<Container size={'lg'} className="">

{parse(info['help_'+i18n.language])}

</Container>
}

</>

     );
}
 
export default Help;