import { Accordion, Button, Center, Checkbox, Modal } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import OfferTable from "./OfferTable";
import Payments from "./Payments";
import { useContext, useState } from "react";
import { AuthContext } from "../hooks/AuthContext";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import axios from "axios";

const OffersTab = ({offers}) => {
    const [opened, { open, close }] = useDisclosure(false);
    
    const [designDuration,setDesignDuration]=useState('')
    const [designCost,setDesignCost]=useState('')
    const [techDuration,setTechDuration]=useState('')
    const [techCost,setTechCost]=useState('')
    const [design,setDesign]=useState(false)
    const [tech,setTech]=useState(false)
    const [loader, setLoader] = useState(false);
    const { user } = useContext(AuthContext);
    const [offerId,setOfferId]=useState();
    const [price,setPrice]=useState();
    const[auth,setAuth]=useState('');
    const { t, i18n } = useTranslation();
    
    const completeOffer=(offer)=>{

        setDesignDuration(offer.design_days)
        setDesignCost(offer.design_cost)
        setTechDuration(offer.tech_days)
        setTechCost(offer.tech_cost)
        setOfferId(offer.id)
        setPrice(offer.price)
        open()
        }
       

    const submitOfferEdit=()=>{
    
    
        setLoader(true)
        axios.post(process.env.REACT_APP_BACKEND_URL+"/api/update-offertype/"+offerId,{
            design_req: design,
            tech_req: tech,
            status: "offer-completed",
            'total_price':`${parseFloat(price)+(tech? parseFloat(techCost):0)+(design? parseFloat(designCost):0)}`,
            },{
            headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${user['auth_token']}`,
            'Access-Control-Allow-Origin':'*'
            },
            
            }) 
            .then(function (response){
                setLoader(false)
                close();
                window.location.reload()
            })
    }

    return ( 
        <>{
        Object.values(offers).map((offer, index) => (
            <Accordion.Item value={offer.type.title_en+index} key={index}>
            <Accordion.Control>
                <div className='ar-dir'>
                <span className='text-sm'><IconCalendar stroke={2} size={12}/> {offer.formatted_created_at}</span>
                <br/>
               <span className='text-blue-1'>{offer.total_price===null ? offer.price :offer.total_price}.0 EGP</span> 
               </div>
                </Accordion.Control>
            <Accordion.Panel>
                
                <OfferTable offer={offer} />
                <Center >
                    { offer.status==='approved' ?

                <Button onClick={(e)=>completeOffer(offer)} className="mb-2" >{t('general.completeOffer')}</Button>
                :<></>
                
                    }
                                        </Center>

                    { offer.payments.length !== 0 ?
                   
                   <Payments offer={offer} />
                   
                    :<></>
                        }
             
                </Accordion.Panel>
            
            
            </Accordion.Item> 
             ))

            }        
            
            
            <Modal opened={opened} onClose={close} title={t('general.completeOffer')} centered>
            {designCost&&designDuration&&techCost&&techDuration&&
          <>
          <p className='text-sm'>{t('general.completeOfferText')}</p>
            <form>
            <Checkbox
            label={`${t('general.design')}`}
            description={`${t('general.duration')} : ${designDuration} ${t('general.days')} ${'  '} ${t('general.cost')} : ${designCost} EGP `}
            checked={design}
            onChange={(e)=>setDesign(e.currentTarget.checked)}
            />
            <br/>
            <Checkbox
            label={`${t('general.techOffer')}`}
            description={`${t('general.duration')} : ${techDuration} ${t('general.days')} ${'  '} ${t('general.cost')} : ${techCost} EGP `}
            checked={tech}
            onChange={(e)=>setTech(e.currentTarget.checked)}
            />
            <br/>
            <Center>
            <Button loading={loader} onClick={submitOfferEdit}>{t('general.submit')}</Button>
            </Center>
                </form> 
                </>
                
            }
            </Modal>
             </>
     );
     
}
 
export default OffersTab;