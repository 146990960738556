import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../hooks/AuthContext";
import { Button, Input, Textarea } from "@mantine/core";
import { notifications } from '@mantine/notifications';
import { IconPhone } from "@tabler/icons-react";

const Settings = () => {
    const { user } = useContext(AuthContext);

    const [phoneNumber,setPhoneNumber]=useState(user.phone);
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [name,setName]=useState(user.name);
    const [nameError, setNameError] = useState("");
    const [address,setAddress]=useState(user.address);
    const [addressError, setAddressError] = useState("");
    const { t, i18n } = useTranslation();
    const [loader, setLoader] = useState(false);

   
   
    const savePhone = async (e) => {
        e.preventDefault();
    
        // Validation logic
        let validationError = null;
        setLoader(true)
        switch (true) {
            case !name || name.trim() === '':
                validationError = t('general.nameEmpty');
                break;
            case !/^[a-zA-Z\s\u0600-\u06FF]+$/.test(name):  // Allow Arabic letters
                validationError = t('general.nameInvalid');
                break;
            case !phoneNumber || phoneNumber.trim() === '':
                validationError = t('general.phoneEmpty');
                break;
            case !/^\d+$/.test(phoneNumber):
                validationError = t('general.phoneInvalid');
                break;
            // Add any other validation cases as needed
        }
    
        if (validationError) {
            notifications.show({
                title: t('general.error'),
                message: validationError,
                color: 'red',
            });
            setLoader(false)

            return;
        }
    
        user.phone = phoneNumber;
        user.name=name;
        user.address=address;
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/auth/edit`,
                {
                    phone: phoneNumber,
                    address: address,
                    name: name,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user['auth_token']}`,
                    },
                }
            );
    
            notifications.show({
                title: t('general.dataUpdatedSuccess'),
                color: 'teal',
            });
            setLoader(false)

            localStorage.setItem('user', JSON.stringify(user));
    
        } catch (error) {
            setLoader(false)

            notifications.show({
                title: t('general.error'),
                message: error.message,
                color: 'red',
            });
        } finally {
            setLoader(false);
        }
    };
    
   
    return ( 
        <div className={` lg:w-2/4 w-3/4 mx-auto drop-shadow-md	ar-right	 my-16 rounded p-4 flex justify-center  items-center bg-white-2 `}>
       
        <form  onSubmit={savePhone} className="w-full">
        <Input.Wrapper label={t('general.fullName')} className="ar-right w-full"
               error={nameError}

         >
          <Input className="mt-2 w-full " 
                            placeholder={t('general.fullName')}
                            value={name}
                            name="name"
                            onChange={(e)=>setName(e.target.value)}
                            />
                           </Input.Wrapper>
        
        
        
        
        
        
        
         <Input.Wrapper label={t('general.phone')} className="ar-right mt-4"
               error={phoneNumberError}

         >
          <Input className="mt-2 w-full " icon={<IconPhone />}
                            placeholder={t('general.phone')}
                            value={phoneNumber}
                            name="phone"
                            onChange={(e)=>setPhoneNumber(e.target.value)}
                            required
                            />
                           </Input.Wrapper>




                         

                           <Textarea className="mt-4"
                                placeholder={t('general.address')}
                                label={t('general.address')}
                                onChange={(e)=>setAddress(e.target.value)}

                                value={address}
                                />
        

                           <Button id="submit-phone" type="submit" loading={loader} className="mt-4">{t('general.confirm')}</Button>
                            </form>
                            </div>


     );
}
 
export default Settings;