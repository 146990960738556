import {  useContext,useState, useEffect } from "react"; //state hook to render changes to the view
import { useTranslation } from "react-i18next";
import { AuthContext } from '../hooks/AuthContext';

const useFetch = (route) => {
  const [data, setData] = useState(null);
  const [statusCode,setStatusCode]=useState(null);
  const [isPending, setIsPending] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const { i18n } = useTranslation();
  const { user } = useContext(AuthContext);

  useEffect(()=>{
    setData(null)
  },[i18n.language])
  
  useEffect(() => {

    setIsPending(true)
    const abortCont = new AbortController();



        fetch("https://dashboard.horizonswimmingpools.com"+"/api/" + route , {
          signal: abortCont.signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user ? user['auth_token']:''}`,
            lang: i18n.language,
          },
        })
          .then((res) => {
            if (!res.ok) {
              throw Error("could not fetch the data");
            }
            return res.json();
          })
          .then((results) => {
            setStatusCode(results.statusCode)
          
            setData(results.data);
            
            setIsPending(false);
            setErrorMsg(null);
          })
          .catch((err) => {
            if (err.name === "AbortError") {
              // console.log(err.message);
            } else {
              setErrorMsg(err.message);
              setIsPending(false);
            }
          });



   

    return () => abortCont.abort();

    
  }, [route, i18n.language]);
 

  return { data, statusCode,isPending, errorMsg };
};
export default useFetch;
