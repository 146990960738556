import { ActionIcon, Button, Menu } from "@mantine/core";
import { Icon3dCubeSphere, IconLogout2, IconSettings, IconUser } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavbarMobile = ({user,links,signOut,changeLanguage}) => {
    const { t, i18n } = useTranslation();
    const navigate=useNavigate();

    const openCompProjects=()=>{
      navigate('/completed-offers')
    }
    return ( 
<div className="ar-dir">
  
  {user ? 
  <div className="">
   <Menu shadow="md" classNames={'mx-4'} width={200}>
   <Menu.Target>
     <Button leftIcon={<IconUser size={'1rem'}/>} variant="white" color="dark" compact>{user.name}</Button>
   </Menu.Target>

   <Menu.Dropdown>
    

     <Menu.Divider />
     <Menu.Item onClick={openCompProjects}  icon={<Icon3dCubeSphere size={14} />}>
        {t('general.projects')}

        
        </Menu.Item>
     {/* <Menu.Label>Danger zone</Menu.Label> */}
     <Menu.Item onClick={(e)=>signOut()} color="red" icon={<IconLogout2 size={14}/>}>{t('general.logOut')}</Menu.Item>
   </Menu.Dropdown>
 </Menu>

 
 </div>
  
  :
  <></>}
{/* -------navs-------- */}
  <div className=" ar-right ">
              {links.map((link,index) => (
<div>

              <HashLink key={index} id={index} smooth 
                  to={link.link}
                  className={`my-4 mx-2.5 ar-right block no-underline	 text-black-0 `}
                  >
                  {t('general.'+link.label)}
              </HashLink>
              
              </div>

              ))}


          </div>
{/* -------lang-------- */}
<div className="my-4 mx-2.5">
<HashLink className="no-underline	text-black-0"
onClick={() => changeLanguage('en')}
>En</HashLink>
{" "}/{" "}
<HashLink className="no-underline	text-black-0"
onClick={() => changeLanguage('ar')}
>Ar</HashLink>
</div>

</div>


     );
}
 
export default NavbarMobile;