import React from 'react';
import { Carousel } from '@mantine/carousel';
import useFetch from '../hooks/useFetch';
import { Button, Container, Image, Skeleton } from '@mantine/core';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import GetOffer from './GetOffer';

const Banners = () => {
    const { data: banners, isPending } = useFetch("banners");
    const { t, i18n } = useTranslation();

    return (
        <div>
            <Skeleton visible={isPending}>
                {banners && banners.length > 0 && (
                    <Carousel withControls={false} slideSize="100%" className='overflow-hidden' mx="auto" withIndicators style={{ height: "70vh" }}>
                        {Object.values(banners).map((banner, index) => (
                            <Carousel.Slide key={index} className='h-full bg-cover bg-center' style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL + '/storage/' + banner.cover_img})` }}>
                                <div className='h-full w-full banner-div ar-dir'>
                                    <Container size={"lg"} className='text-white-0 h-full flex flex-row justify-start items-center'>
                                        <div>
                                            <h1>{banner['title_' + i18n.language]}</h1>
                                            {banner['desc_' + i18n.language] && (
                                                <p>{parse(banner['desc_' + i18n.language])}</p>
                                            )}
                                            <GetOffer />
                                        </div>
                                    </Container>
                                </div>
                            </Carousel.Slide>
                        ))}
                    </Carousel>
                )}
            </Skeleton>
        </div>
    );
}

export default Banners;
